<template>
  <b-card
      class="purchasepaid-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchasepaidForm" class="edit-form mt-2">
      <b-row>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="付款金额"
              label-for="amount"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="amount"
                size="sm"
                v-model="purchasepaid.amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="付款日期"
              label-for="pay_date"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                v-model="purchasepaid.pay_date"
                class="form-control"
                placeholder="请选择付款时间"
            />
          </b-form-group>
        </b-col>
        <b-col md="4"></b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="备注"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="remark"
                size="sm"
                v-model="purchasepaid.memo"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="相关附件"
              label-for="attachments"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="purchasepaid.loaded" :theme="'files'"
                               :attachment_id="'attachments'"
                               :id="purchasepaid.attachments"
                               :object_type="'purchase_paid'"
                               :object_id="purchasepaid.order_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
              v-if="purchasepaid.state==0"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchasepaidStore from './purchasepaidStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import {useRouter} from "@core/utils/utils";
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,
  },
  data() {
    return {
      id: ref(0),
      purchasepaid: ref({}),
      order_id: 0,
    }
  },
  setup() {
    const toast = useToast()

    const router = useRouter()


    // Register module
    if (!store.hasModule('purchasepaid')) store.registerModule('purchasepaid', purchasepaidStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchasepaid')) store.unregisterModule('purchasepaid')
    })

    const edit = function () {
      store.dispatch('purchasepaid/edit', {id: this.id}).then(res => {
        this.purchasepaid = res.data.data
        this.purchasepaid.purchase_order_id = this.order_id
        this.purchasepaid.pay_date = toDate(this.purchasepaid.pay_date)
      })
    }

    const view = function () {
      store.dispatch('purchasepaid/view', {id: this.id}).then(res => {
        this.purchasepaid = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.purchasepaid.amount === undefined || this.purchasepaid.amount === "") {
        toast.error("请填写 付款金额!")
        return false
      }
      if (this.purchasepaid.pay_date === undefined || this.purchasepaid.pay_date === "") {
        toast.error("请填写 付款日期!")
        return false
      }
      this.purchasepaid.pay_date = second(this.purchasepaid.pay_date)
      store.dispatch('purchasepaid/save', this.purchasepaid).then(res => {
        toast.success('数据已保存!')
        this.$router.push({name: 'apps-purchasepaid-list',query:{order_id:this.order_id}});
      })
    }

    const onUploaded = function (id, attachment, result) {
      if (attachment.length > 1) {
        this.purchasepaid[id] = result
      } else {
        this.purchasepaid[id] = attachment.attachment_id
      }
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      toDate,
      second,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.order_id = this.$route.query.order_id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>